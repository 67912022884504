
import { Component, Ref, Vue } from 'vue-property-decorator';
import { marked } from 'marked';
import {
  Proposal,
  ProposalTransaction,
} from "@maplabs/map-atlas-sdk";
import { UserModule } from '@/store/modules/user';
import { AppModule } from '@/store/modules/app';
import { articleURLFromID, getArticle, getArticleTitleAndContent } from '@/utils/article_tool';
import { throttle } from 'lodash';

@Component
export default class CreateProposalPage extends  Vue {
  private showAddDialog = false;
  private inputDestinationAddress = '';
  private inputValue = '';
  private inputCalldata = '';
  private inputDataLength = '';

  private mipURL = '';
  private mipID = '';
  private loadingContent = false;
  @Ref()
  private proposalEle?: HTMLElement;

  private transactions: Proposal = [];

  private editingTransaction: ProposalTransaction | null = null;

  private mipIDInput = throttle(() => {
    this.mipIDChange();
  }, 200);

  private async tapPropose() {
    if (!this.mipURL) {
      AppModule.TOAST({
        type: "error",
        message: "Please input MEP ID",
      });
      return;
    }
    if (UserModule.contractKit) {
      try {
        AppModule.START_LOADING("Proposing");
        const res = await UserModule.contractKit.governanceContract.propose(
          this.transactions,
          this.mipURL
        );
        const proposalID = res.events.ProposalQueued.returnValues.proposalId;
        AppModule.END_LOADING();
        AppModule.TOAST(
          {
            type: 'success',
            message: "Success",
          }
        );
        this.$router.push("/governance/proposal/" + proposalID);
      } catch (e) {
        console.error(e);
        AppModule.END_LOADING();
        AppModule.TOAST(
          {
            type: 'error',
            message: e.message,
          }
        );
      }

    }
  }

  private tapAddButton() {
    this.showAddDialog = true;
  }

  private numberInput(value: string) {
    this.$nextTick(() => {
      this.inputDataLength = value.replaceAll('.', '');
    });
  }

  private async submitTransaction() {
    if (!(this.inputDestinationAddress)) {
      AppModule.TOAST({
        type: 'error',
        message: 'Please input destination address',
      });
      return;
    }
    if (!this.inputCalldata) {
      AppModule.TOAST({
        type: 'error',
        message: 'Please input calldata',
      });
      return;
    }
    if (this.editingTransaction) {
      this.editingTransaction.to = this.inputDestinationAddress;
      this.editingTransaction.value = this.inputValue || "0";
      this.editingTransaction.input = this.inputCalldata;
    } else {
      this.transactions = this.transactions.concat([
        {
          to: this.inputDestinationAddress,
          value: this.inputValue || "0",
          input: this.inputCalldata
        }
      ]);
      this.showAddDialog = false;
    }
    this.clearDialog();
  }

  private tapEdit(index: number) {
    this.editingTransaction = this.transactions[index];
    this.showAddDialog = true;
    this.inputValue = this.editingTransaction.value;
    this.inputCalldata = this.editingTransaction.input;
    this.inputDestinationAddress = this.editingTransaction.to!;
  }

  private tapDelete(index: number) {
    const arr = [...this.transactions];
    arr.splice(index, 1);
    this.transactions = [...arr];
  }

  private clearDialog() {
    this.editingTransaction = null;
    this.inputValue = '';
    this.inputCalldata = '';
    this.inputDestinationAddress = '';
    this.inputDataLength = '';
  }

  private async mipIDChange() {
    this.loadingContent = true;
    if (this.mipID && parseInt(this.mipID, 10)) {
      try {
        this.mipURL = articleURLFromID(this.mipID);
        const res = await getArticle(this.mipID);
        this.loadingContent = false;
        const {title, content} = getArticleTitleAndContent(res);
        this.$nextTick(() => {
          if (this.proposalEle) {
            this.proposalEle.innerHTML = marked.parse("# " + title + "\n\n\n" + content);
          }
        })
      } catch (e) {
        console.error(e);
        this.loadingContent = false;
        this.mipURL = "";
        this.$nextTick(() => {
          if (this.proposalEle) {
            this.proposalEle.innerHTML = "";
          }
        })
      }

    } else {
      this.mipURL = "";
      this.loadingContent = false;
      this.$nextTick(() => {
        if (this.proposalEle) {
          this.proposalEle.innerHTML = "";
        }
      })
    }
  }
};
